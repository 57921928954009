  /*
    Flaticon icon font: Flaticon
    Creation date: 08/09/2018 00:19
    */

@font-face {
  font-family: "Flaticon";
  src: url("../fonts/Flaticon.eot");
  src: url("../fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
       url("../fonts/Flaticon.woff") format("woff"),
       url("../fonts/Flaticon.ttf") format("truetype"),
       url("../fonts/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-bad:before { content: "\f100"; }
.flaticon-anesthesia:before { content: "\f101"; }
.flaticon-dental:before { content: "\f102"; }
.flaticon-avatar:before { content: "\f103"; }
.flaticon-bottle:before { content: "\f104"; }
.flaticon-neurology:before { content: "\f105"; }
.flaticon-thinking:before { content: "\f106"; }
.flaticon-first-aid-kit:before { content: "\f107"; }
.flaticon-doctor:before { content: "\f108"; }
.flaticon-health:before { content: "\f109"; }
.flaticon-medicine:before { content: "\f10a"; }
.flaticon-medical:before { content: "\f10b"; }
.flaticon-department:before { content: "\f10c"; }
.flaticon-department-1:before { content: "\f10d"; }
.flaticon-24-hours:before { content: "\f10e"; }
.flaticon-blood-sample:before { content: "\f10f"; }
.flaticon-pharmacy:before { content: "\f110"; }
.flaticon-anatomy:before { content: "\f111"; }
.flaticon-beauty:before { content: "\f112"; }
.flaticon-ribbon:before { content: "\f113"; }
.flaticon-cancer:before { content: "\f114"; }
.flaticon-lung-cancer:before { content: "\f115"; }
.flaticon-maternity:before { content: "\f116"; }
.flaticon-patient:before { content: "\f117"; }
.flaticon-pharmacy-1:before { content: "\f118"; }
.flaticon-recovered:before { content: "\f119"; }
.flaticon-nurse:before { content: "\f11a"; }
.flaticon-ambulance:before { content: "\f11b"; }
.flaticon-ambulance-1:before { content: "\f11c"; }
.flaticon-checkup:before { content: "\f11d"; }
.flaticon-doctor-1:before { content: "\f11e"; }
.flaticon-ultrasound:before { content: "\f11f"; }
.flaticon-surgery:before { content: "\f120"; }
.flaticon-tooth:before { content: "\f121"; }
.flaticon-computer:before { content: "\f122"; }
.flaticon-body-scan:before { content: "\f123"; }